import React from 'react';
import MenuItem from '../MenuItem';
import './Menu.css';

const Menu = ({ items }) => {
  return (
    <div className="menu">
      {items.map(item => (
        <MenuItem key={item.id} item={item} />
      ))}
    </div>
  );
};

Menu.Item = MenuItem;

export default Menu;
