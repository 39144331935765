import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import withModalDialog from '../hocs/withModalDialog';
import { getKnowledgeArticle } from '../../actions/knowledge';
import Menu from './Menu';
import ArticleList from './ArticleList';
import './Knowledge.css';

class KnowledgeBase extends Component {
  state = {
    articles: [],
    currentArticleId: null,
    currentSectionId: null,
    content: null,
  };

  handleMenuItemClick = section => () => {
    this.setState({
      articles: section.know_base_articles,
      currentSectionId: section.id,
    });
  };

  handleArticleTitleClick = articleId => () => {
    if (articleId === this.state.currentArticleId) {
      return this.setState({ currentArticleId: null });
    }

    getKnowledgeArticle(articleId).then(res => {
      this.setState({
        currentArticleId: articleId,
        content: res.know_base_article,
      });
    });
  };

  render () {
    const { knowledgeSections, loadSections } = this.props;
    const { articles, currentArticleId, currentSectionId, content } = this.state;

    if (!knowledgeSections.get()) {
      loadSections();
      return null;
    }

    const sections = knowledgeSections.get();

    return (
      <Fragment>
        <Menu
          items={sections}
          currentItemId={currentSectionId}
          onClick={this.handleMenuItemClick}
        />

        <ArticleList
          items={articles}
          onClick={this.handleArticleTitleClick}
          currentItemId={currentArticleId}
          currentItemContent={content}
        />
      </Fragment>
    );
  }
}

export default withModalDialog(observer(KnowledgeBase), 'knowledge');
