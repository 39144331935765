import React, { Fragment } from 'react';

const ArticleList = ({
  items,
  onClick,
  currentItemId,
  currentItemContent,
}) => (
  <div className="knowledge__article-list">
    {items.map(item => (
      <Fragment key={`article-${item.id}`}>
        <div
          className="knowledge__article-title"
          onClick={onClick(item.id)}
        >
          {item.title}
        </div>

        {currentItemId === item.id && (
          <Fragment>
            <div
              className="knowledge__article-content"
              dangerouslySetInnerHTML={{ __html: currentItemContent.intro }}
            />

            {currentItemContent.paragraphs.map(p => (
              <Fragment>
                <div className="knowledge__article-content">
                  {p.content}
                </div>

                {p.picture.url && (
                  <img
                    className="knowledge__article-img"
                    alt="lady popular"
                    src={`https://socapp.castlot.ru/${p.picture.url}`} />
                )}
              </Fragment>
            ))}
          </Fragment>
        )}
      </Fragment>
    ))}
  </div>
);

export default ArticleList;
