import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Slider from 'react-slick';
import './FriendList.css';

const settings = {
  dots: false,
  className: "slider variable-width",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
};

class FriendList extends Component {
  get items() {
    const { friends } = this.props;

    return [
      ...friends.get(),
      ...Array.from({ length: 15 }, (v, k) => ({id: k})),
    ];
  }

  friendItemClickHandler = ({id, is_app_user}) => (e) => {
    const {showInviteBox, showRequestBox} = this.props;

    if (is_app_user) {
      return showRequestBox(id);
    }

    return showInviteBox();
  };

  render () {
    const { showQuestMissions } = this.props;

    return (
      <div className="friend-list">
        <Slider {...settings}>
          <div className="quest-button" onClick={showQuestMissions}>
            <div className="quest-button-pulse" />
            <div className="quest-button-img" />
          </div>

          {this.items.map(item => (
            <div
              key={item.id}
              className="friend-item"
              onClick={this.friendItemClickHandler(item)}
            >
              {item.photo && (
                <img
                  className="friend-item__img"
                  src={item.photo}
                  title={item.name}
                  alt={item.name}
                />
              )}
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

export default observer(FriendList);
