import VkAdapter from './adapters/vk';
import OkAdapter from './adapters/ok';
import MyAdapter from './adapters/my';
import PlvAdapter from './adapters/plv';
import PlvsAdapter from './adapters/plvs';

export default class SocialClient {
  static instance = null;
  
  constructor(viewerId, socialUin, config) {
    if (SocialClient.instance) {
      throw new Error("ups");
    }

    this.gamer_id = viewerId;

    console.log(config);
    this.adapter = (function() {
      switch (socialUin) {
        case 'ok': return new OkAdapter(viewerId, config.soc_conf_id);
        case 'my': return new MyAdapter(viewerId, config.soc_conf_id, config.pk);
        case 'vk': return new VkAdapter(viewerId, config.soc_conf_id);
        case 'plv': return new PlvAdapter(viewerId, config.soc_conf_id);
        case 'plvs': return new PlvAdapter(viewerId, config.soc_conf_id);
        default: return null;
      }
    })();
  }

  showInviteBox(message) {
    this.adapter.showInviteBox(message);
  }
  
  showOrderBox(price, label, value, bonus) {
    this.adapter.showOrderBox(price, label, value, bonus);
  }

  showPaymentWindow(cost, itemId) {
    this.adapter.showPaymentWindow(cost, itemId);
  }

  showSubscriptionBox(action, item, subscriptionId) {
    this.adapter.showSubscriptionBox(action, item, subscriptionId);
  }
    
  wallPost(message, photo, url, msg) {
    this.adapter.wallPost(message, photo, url, this.gamer_id, msg);
  }
    
  showRequestBox(friend_id, message, photo) {
    this.adapter.showRequestBox(friend_id, message, photo);
  }
    
  resizeWindow(height) {
    this.adapter.resizeWindow(height);
  }
  
  async getFriends(){
    return await this.adapter.getFriends();
  }
  
  getShopViewDetails(){
    return this.adapter.getShopViewDetails();
  }
  
  checkQuestCompleted(socialAction){
    return this.adapter.checkQuestCompleted(socialAction)
  }
  
  getCurrencyStr(n){
    const cases = this.adapter.getCurrencyCases();
    const rem100 = n % 100;
    const rem10 = n % 10;

    if (rem100 >= 11 && rem100 <= 19)
      return cases.manyGenitive;

    if (rem10 === 1)
      return cases.nominative;
    
    if (rem10 >= 2 && rem10 <= 4)
      return cases.genitive;
    
    return cases.manyGenitive;
  }

  static getInstance(viewerId, socialUin, config) {
    if (!SocialClient.instance) {
      SocialClient.instance = new SocialClient(viewerId, socialUin, config);
    }
    
    return SocialClient.instance;
  }
}
