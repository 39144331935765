import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import './Modal.css';

const modalRoot = document.getElementById('modal');

const Modal = ({
  status,
  className,
  children,
  onClose = Function.prototype,
}) => {
  if (status.get() !== 'open') {
    return null;
  }
  
  const handleCloseClick = () => {
    onClose();
    status.set('close');
  };

  const cls = cx('modal', {
    [className]: className,
  });

  const component = () => (
    <div className={cls}>
      <div className="modal__close-btn" onClick={handleCloseClick} />
      {children}
    </div>
  );

  return ReactDOM.createPortal(component(), modalRoot);
};

export default Modal;
