import bridge from '@vkontakte/vk-bridge';

export default class VkAdapter {
    app_id = 4404645;
    group_id = 66723154;
    initialized = false;
    
    constructor(soc_user_id, soc_conf_id) {
        this.soc_user_id = soc_user_id;
        this.soc_conf_id = soc_conf_id;
        
        // still need for like button
        let script = document.createElement('script');
        script.addEventListener('load', () => {
            this.handleOnLoad();
        });
        script.type = "text/javascript";
        script.src = "https://vk.com/js/api/xd_connection.js?2"; 
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    handleOnLoad() {
        bridge.send('VKWebAppInit')
        .then((data) => {
            console.log("Init");
        })
        .catch((error) => {
          console.log(error);
        });
    
        window.VK.init(() => {
            window.VK.Widgets.Like('likes', {type: "mini"}, this.soc_conf_id);
            this.initialized = true;
        });
    }

  showInviteBox(message) {
      bridge.send('VKWebAppShowInviteBox')
      .then((data) => {
          console.log(data);
      })
      .catch((e) => {
          console.log(e);
      });
  }

  showRequestBox(friend_id, message, photo) {
      
  }

  showOrderBox(price, label, value) {
      bridge.send('VKWebAppShowOrderBox', {
          type: 'item',
          item: `shop_value:${value}`
      })
      .then((data) => {
          if (data.success) {
              console.log(data)
          }})
      .catch((error) => {
          console.log(error);
      });
  }
  
  showPaymentWindow(price, itemId){
      bridge.send('VKWebAppShowOrderBox', {
          type: 'item',
          item: `item:${itemId}`
      })
      .then((data) => {
          if (data.success) {
              console.log(data)
          }})
      .catch((error) => {
          console.log(error);
      });
  }

  showSubscriptionBox(action, id, subscription_id) {
      window.VK.callMethod('showSubscriptionBox', action, { item: id, subscription_id: subscription_id });
  }

  wallPost(message, photo, url, gamer_id, msg) {
        
  }
    
  async getFriends() {
        try{
            const authData = await this.getVkAuthData();

            if (authData.access_token) {
                var friendsData = await bridge.send('VKWebAppCallAPIMethod', {
                    method: 'friends.get',
                    params: {
                        v: '5.131',
                        order: 'random',
                        fields: 'photo_200_orig, nickname',
                        count: 50,
                        access_token: authData.access_token
                    }});

                if (friendsData.response) {
                    return friendsData.response.items.map(f => {
                        return {
                            id: f.id,
                            name: f.first_name + '' + f.last_name,
                            photo: f.photo_200_orig
                        }
                    });
                }
            }   
        }
        catch(e){
            console.log(e);
            return [];
        }
  }
  
  async getVkAuthData(){
      return await bridge.send('VKWebAppGetAuthToken', {
          app_id: this.app_id,
          scope: 'friends,status'
      });
  }
    
  resizeWindow(height) {
      bridge.send('VKWebAppResizeWindow', {
          width: 1000,
          height: height
      })
      .then((data) => {})
      .catch((error) => {});
  }
  
  getShopViewDetails() {
        return {
            diamondPictureSrc: 'assets/shop/gem-big.png',
            diamondIconSrc: 'assets/shop/gem-small.png',
        }
  }
  
    getCurrencyCases(){
        return {
            nominative: 'голос',
            genitive: 'голоса',
            manyGenitive: 'голосов'
        };
    }

    async checkQuestCompleted(socialAction){
        switch (socialAction){
            case "group_join":
                const subscribeData = await bridge.send('VKWebAppGetGroupInfo', {
                    group_id: this.group_id
                });
                
                if (subscribeData.id) {
                   if (subscribeData.is_member){
                       return true;
                   }
                }
                
                break;
                
            case "like_game":
                const authData = await this.getVkAuthData();
                if (authData.access_token) {
                    const likesData = await bridge.send('VKWebAppCallAPIMethod', {
                        method: 'likes.getList',
                        params: {
                            v: '5.120',
                            type: 'sitepage',
                            owner_id: 4404645,
                            filters: 'likes',
                            item_id: this.soc_conf_id,
                            offset: 0,
                            count: 100,
                            access_token: authData.access_token
                        }});

                    if (likesData.response) {
                        for (let id in likesData.response.items){
                            if (id == this.soc_user_id){
                                return true;
                            }
                        }
                    }
                }
                
                return true;
                break;
        }
        
        return false;
    }

  waitInit(fun) {
    if(this.initialized === false) {
        window.setTimeout(() => this.waitInit(fun), 100);
    }
    else{
        fun()
    }
  }
}
