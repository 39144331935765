import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withModalDialog from '../hocs/withModalDialog';
import './ServerMessage.css';

class ServerMessage extends Component {
  render () {
    const { message } = this.props;

    const serverMessage = message.get();

    return (
      <div>
        <div className="server-message__title">
          { serverMessage.title }
        </div>
        <div className="server-message__body">
          { serverMessage.body }
        </div>
      </div>
    );
  }
}

export default withModalDialog(observer(ServerMessage), 'server-message');
