import React from 'react';
import cx from 'classnames';
import './ShopItemButton.css';

const ShopItemButton = ({
  disabled,
  onClick,
}) => {
  const cls = cx('shop-item__button', {
    'shop-item__button--disabled': disabled,
  });
  
  if (disabled){
    onClick = null;
  }
  
  return (
    <div className={cls} onClick={onClick}>
      Купить
    </div>
  );
};

export default ShopItemButton;
