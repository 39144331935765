import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Header from './components/header';
import Footer from './components/footer';
import Game from './components/game';
import KnowledgeBase from './components/knowledgebase';
import Shop from './components/shop';
import Subscription from './components/subscription';
import ServerMessage from './components/server_message';
import QuestMissions from './components/quest_missions';
import Store from './store';

import './App.css';

const App = () => {
    const store = new Store();

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.getElementById("wrapped-game").requestFullscreen();
        } else {
            if (document.getElementById("wrapped-game").exitFullscreen) {
                document.getElementById("wrapped-game").exitFullscreen();
            }
        }
    };
    
    store.load();
    
    return (
        <div className="App">
            <Header
                config={store.config}
                shopStatus={store.shopStatus}
                enterFullscreen={toggleFullScreen}
                fullscreenStatus={store.isFullscreen}
                knowledgeStatus={store.knowledgeStatus}
                subscriptionStatus={store.subscriptionStatus}
            />
            
            <Game gameUrl={store.gameUrl}/>

            <Shop
                status={store.shopStatus}
                shopItems={store.shopItems}
                loadCustomShopItems={store.loadCustomShopItems}
                customShopItem={store.customShopItem}
                showOrderBox={store.showOrderBox}
            />

            <KnowledgeBase
                status={store.knowledgeStatus}
                loadSections={store.loadKnowledgeSections}
                knowledgeSections={store.knowledgeSections}
            />

            <Subscription
                status={store.subscriptionStatus}
                subscriptions={store.subscriptions}
                showOrderBox={store.showSubscriptionBox}
            />

            <ServerMessage
                status={store.serverMessageStatus}
                message={store.serverMessage}
            />

            <QuestMissions
                status={store.questMissionsStatus}
                missions={store.questMissions}
                getQuestMissionReward={store.getQuestMissionReward}
            />

            <Footer
                friends={store.friends}
                onFriendItemClick={store.fri}
                showInviteBox={store.showInviteBox}
                showRequestBox={store.showRequestBox}
                showQuestMissions={store.showQuestMissions}
            />
        </div>
    );
};

export default observer(App);
