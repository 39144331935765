import { observable } from 'mobx';
import { getIframeUrl, getWindowHeight } from '../actions/game';
import { getConfig } from '../actions/social_conf';
import { getFriends } from '../actions/social';
import { getShopItems, getCustomShopItem, createNewOrder } from '../actions/shop';
import { getKnowledgeSections } from '../actions/knowledge';
import { getSubscriptionList } from '../actions/subscription';
import { getQuestMissions, takeReward } from '../actions/quest_missions';
import SocialClient from '../lib/SocialClient';
import LadyArenaSocket from "../lib/LadyArenaSocket";

const searchParams = () => {
  const { pathname, search } = window.location;
  
  const params = new URLSearchParams(search);
  
  let queryParams = {}
  for (let entrie of params.entries()) {
    queryParams[entrie[0]] = entrie[1]
  }
  
  return {
    socialUin: () => {
      return params.get('social');
    },
    viewerId: () => {
      return params.get('viewer_id') || params.get('logged_user_id');
    },
    query: () => JSON.stringify(queryParams)
  };
};

export default class Store {
  _gamerId = null;
  _platform = null;
  _viewerId = null;
  _query = null;

  gameUrl = observable.box(null);
  config = observable.box(null);
  
  shopStatus = observable.box('close');
  knowledgeStatus = observable.box('close');
  subscriptionStatus = observable.box('close');
  serverMessageStatus = observable.box('close');
  questMissionsStatus = observable.box('close');

  shopItems = observable.box(null);
  customShopItem = observable.box(null);
  
  isFullscreen = observable.box(false);
  friends = observable.box([]);
  
  knowledgeSections = observable.box(null);
  subscriptions = observable.box([]);

  serverMessage = observable.box({title: '', body: ''});
  questMissions = observable.box([]);

  constructor () {
    const params = searchParams();
    
    this._platform = params.socialUin();
    this._viewerId = params.viewerId();
    this._query = params.query();
  }

  load () {
    Promise.all([
      this.loadGameUrl(),
      this.loadConfig(),
    ]).then(res => {
      this._gamerId = res[0].gamerId;
      this.gameUrl.set(res[0].gameUrl);
      
      this.config.set(res[1]);

      //Init social instance
      SocialClient.getInstance(this._viewerId, this._platform, res[1]);
      
      //this.loadWindowHeight();
      this.loadFriends();
      this.loadShopItems();
      this.loadSubscriptions();
      this.loadQuestMissions();
      
      this._socket = LadyArenaSocket({
           store: this,
           viewerId: this._viewerId,
           socialUin: this._platform,
           gamerId: this._gamerId,
           config: this.config.get(),
         });
    });
  }
  
  loadGameUrl () {
    return getIframeUrl(
      this._platform,
      this._query
    );
  }
  
  loadConfig () {
    return getConfig(
      this._platform
    );
  }
  
  async loadWindowHeight() {
    let success = false;
    
    while(!success) {
      const height = await getWindowHeight(this._gamerId, this._platform)
      if (Number.isInteger(height)) {
        if (SocialClient.instance) {
          SocialClient.instance.resizeWindow(height);
          success = true;
        }
      }

      await this.sleep(1000);
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  loadShopItems () {
    return getShopItems(this._platform)
      .then(res => {
        this.shopItems.set(res);
      });
  }
  
  loadCustomShopItems = (value) => {
    return getCustomShopItem(this._platform, value)
      .then(res => {
        this.customShopItem.set(res);
      });
  };

  loadKnowledgeSections = () => {
    return getKnowledgeSections()
      .then(res => {
        this.knowledgeSections.set(res.know_base_sections);
      });
  };
  
  showOrderBox = (price, label, value, bonus) => {
    SocialClient.instance.showOrderBox(price, label, value, bonus);
  };
  
  showSubscriptionBox = (action, itemId, subscriptionId) => {
    SocialClient.instance.showSubscriptionBox(action, itemId, subscriptionId)
  }

  loadFriends = async () => {
    const friends = await SocialClient.instance.getFriends();
    this.friends.set(friends);
  };

  loadSubscriptions = () => {
    return getSubscriptionList(
      this._gamerId,
      this._platform
    ).then(res => {
      this.subscriptions.set(res);
    });
  };

  loadQuestMissions = () => {
    return getQuestMissions(
      this._gamerId,
      this._platform
    ).then(res => {
      this.questMissions.set(res);
    });
  };

  getQuestMissionReward = questMissionId => {
    return takeReward(
      this._gamerId,
      this._platform,
      questMissionId
    ).then(res => {
      this.loadQuestMissions();
    });
  };

  showInviteBox = () => {
    SocialClient.instance.showInviteBox(
      this.config.get().invite_message
    );
  };

  showRequestBox = (friendId) => {
    SocialClient.instance.showRequestBox(
      friendId,
      this.config.get().invite_message,
      this.config.get().game_logo_url
    );
  };

  showQuestMissions = () => {
    this.questMissionsStatus.set('open');
  }
}

/*$(document).ready(function() {
  var b = $('body'),
      origin = b.attr('origin'),
      gamer_id = b.attr('gamer_id'),
      soc_user_id = b.attr('soc_user_id'),
      soc_conf_id = b.attr('soc_conf_id'),
      pk = b.attr('pk'),
      app_id = b.attr('app_id'),
      game_id = b.attr('game_id'),
      gamer_social = gamer_id + "_" + soc_conf_id;
});*/
