import bridge from "@vkontakte/vk-bridge";

export default class OkAdapter {
  constructor(soc_user_id, soc_conf_id) {
    this.soc_user_id = soc_user_id;
    this.soc_conf_id = soc_conf_id;
    this.feedPostingObject = {};

    window.API_callback = function(method, result, data) {
      if (method === "showConfirmation" && result === "ok") {
        window.FAPI.Client.call(this.feedPostingObject, (status, data, error) => {
          console.log(`${status} ${data} ${error["error_msg"]}`);
        }, data);
      }
    }
    
    let script = document.createElement('script');
    script.type = "text/javascript";
    
    script.addEventListener('load', (e) => {
      this.scriptOnloadHandler();
    });
    
    script.src = "//api.ok.ru/js/fapi5.js";
    script.attributes["defer"] = "defer";
    
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  scriptOnloadHandler() {
    const rParams = window.FAPI.Util.getRequestParameters();
    window.FAPI.init(rParams["api_server"], rParams["apiconnection"], () => {
      console.log('ok');
    }, (error) => {
      console.log(error);
    });
  }

  showInviteBox(message) {
    window.FAPI.UI.showInvite(message);
  }

  showOrderBox(price, label, value, bonus) {
    let title = "";
    if (bonus > 0){
      title = `${value} + ${bonus} Кристаллов`;
    }
    else{
      title = `${value} Кристаллов`;
    }
    
    window.FAPI.UI.showPayment(title, label, `shop_value:${value}`, price, null, null, "ok", "true");
  }
  
  showPaymentWindow(price, itemId) {
    const label = "Внутриигровой предмет";
    window.FAPI.UI.showPayment(label, label, `item:${itemId}`, price, null, null, "ok", "true");
  }
  
  showSubscriptionBox(action, item) {}

  wallPost(message, photo, url, gamer_id) {}

  async getFriends() {
    try{
      const response = await new Promise(resolve => {
        window.FAPI.Client.call({"method": "friends.get", "fields":"first_name,last_name,location,pic128x128",}, (status, data, error) => resolve({status, data, error}));
      });
      
      if (response.data){
        const usersResponse = await new Promise(resolve => {
          window.FAPI.Client.call(
              {
                "method": "users.getInfo",
                "fields":"first_name,last_name,pic128x128",
                "uids": response.data
              }, (status, data, error) => resolve({status, data, error}));
        });
        
        if (usersResponse.data){
          console.log(usersResponse.data)
          return usersResponse.data.map(f => {
            return {
              id: f.uid,
              name: f.first_name + ' ' + f.last_name,
              photo: f.pic128x128,
            }
          });
        }
      }
    }
    catch(e){
      console.log(e);
      return [];
    }
  }

  async checkQuestCompleted(socialAction){
    switch (socialAction){
      case "group_join":
        const userResponse = await new Promise(resolve => {
          window.FAPI.Client.call(
              {
                "method": "users.getCurrentUser",
                "fields": "uid",
              }, (status, data, error) => resolve({status, data, error}));
        });
        if (!(userResponse.status === "ok"))
          return false;
        
        const response = await new Promise(resolve => {
          window.FAPI.Client.call(
              {
                "method": "group.getUserGroupsByIds",
                "group_id": "52685307314389",
                "uids": [userResponse.data.uid],
                "fields": "member_status,access_type,role,status",
              }, (status, data, error) => resolve({status, data, error}));
        });
        
        if (response.data && response.data.length > 0 && response.data[0].status === "ACTIVE") {
          console.log(response.data)
          return true;
        }

        break;

      case "like_game":
        const authData = await this.getVkAuthData();
        if (authData.access_token) {
          const likesData = await bridge.send('VKWebAppCallAPIMethod', {
            method: 'likes.getList',
            params: {
              v: '5.120',
              type: 'sitepage',
              owner_id: 4404645,
              filters: 'likes',
              item_id: this.soc_conf_id,
              offset: 0,
              count: 100,
              access_token: authData.access_token
            }});

          if (likesData.response) {
            for (let id in likesData.response.items){
              if (id == this.soc_user_id){
                return true;
              }
            }
          }
        }

        return true;
        break;
    }

    return false;
  }

  showRequestBox(friend_id, message, photo) {
    window.FAPI.UI.showNotification(message);
  }

  resizeWindow(height) {
    window.FAPI.UI.setWindowSize(760, height);
  }

  getShopViewDetails() {
    return {
      diamondPictureSrc: 'assets/shop/gem-big.png',
      diamondIconSrc: 'assets/shop/gem-small.png',
    }
  }

  getCurrencyCases(){
    return {
      nominative: 'ок',
      genitive: 'ока',
      manyGenitive: 'оков'
    };
  }
}
