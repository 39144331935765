import React, { Fragment } from 'react';
import cx from 'classnames';
import './MenuItem.css';

import groupImg from '../../assets/lady/group.png';
import supportImg from '../../assets/lady/support.png';
import fullscreenImg from '../../assets/lady/full_screen.png';
import faqImg from '../../assets/lady/faq.png';
import shopImg from '../../assets/lady/shop.png';
import gemImg from '../../assets/lady/gem.png';

const images = {
  group: groupImg,
  support: supportImg,
  fullscreen: fullscreenImg,
  faq: faqImg,
  shop: shopImg,
  gem: gemImg,
};

const MenuItem = ({ item }) => {
  const cls = cx('menu-item', {
    'menu-item--labeled': item.label,
  });

  return (
    <div id={item.id} className={cls} onClick={item.onClick}>
      {item.image && (
        <Fragment>
          <img className="menu-item__img" src={images[item.image]} title={item.title} />
          {item.label && (
            <div className="menu-item__label">{item.label}</div>
          )}
        </Fragment>
      )}

      {!item.image && item.title && (
        <div className="menu-item__txt">{item.title}</div>
      )}
    </div>
  );
};

export default MenuItem;
