import React from 'react';
import cx from 'classnames';
import './MenuItem.css';

const MenuItem = ({
  title,
  onClick,
  active,
}) => {
  const cls = cx('knowledge__menu-item', {
    'knowledge__menu-item--active': active,
  });

  return (
    <div
      className={cls}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default MenuItem;
