import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withModalDialog from '../hocs/withModalDialog';
import SubscriptionItem from './SubscriptionItem';
import './Subscription.css';

class Subscription extends Component {
  handleSubscriptionItemClick = (item) => () => {
    if (item.gamer_subscription_id){
      this.props.showOrderBox('cancel', item.id, item.gamer_subscription_id);
    }
    else{
      this.props.showOrderBox('create', item.id); 
    }
  };

  render () {
    const { subscriptions } = this.props;

    const items = subscriptions.get();

    return (
      <div className="subscription-list">
        {items.map((item, index) => (
          <SubscriptionItem
            key={`subscription-${item.value}`}
            item={item}
            onClick={this.handleSubscriptionItemClick(item)}
          />
        ))}
      </div>
    );
  };
}

export default withModalDialog(observer(Subscription), 'subscription');
