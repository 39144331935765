import React from 'react';
import './SubscriptionItem.css';

const SubscriptionItem = ({ item, onClick }) => {
    let btnText = `Подписка ${item.cost} голосов / нед.`;
    if (item.gamer_subscription_id){
        btnText = "Уже у вас, отменить?";
    }
    
  return (
    <div className="subscription-item">
      <div className="subscription-item__header">
      </div>
      <div className="subscription-item__title">
        {item.label}
      </div>

      <div className="subscription-item__rule">
        Оформив эту подписку вы будете получать бриллианты
        <div>с бонусом <strong className="subscription-item__rule--percent">{item.percent}%</strong> каждую неделю!</div>
      </div>

      <div className="subscription-item__diamond">
      </div>

      <div className="subscription-item__offer">
        Еженедельно вам будут начисляться
        <div><strong>{item.value} бриллиантов<br/>по цене {item.discount}!</strong></div>
      </div>

      <div className="subscription-item__btn" onClick={onClick}>
          {btnText}
      </div>
    </div>
  );
};

export default SubscriptionItem;
