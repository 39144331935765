import bridge from "@vkontakte/vk-bridge";

export default class MyAdapter {
  constructor(soc_user_id, soc_conf_id, private_key) {
    this.soc_conf_id = soc_conf_id;
    this.soc_user_id = soc_user_id;
    this.private_key = private_key;
    this.init = false;
        
    let script = document.createElement('script');
    script.type = "text/javascript";
    script.addEventListener('load', () => {
      this.scriptOnloadHandler();
    });
    script.src = "https://connect.mail.ru/js/loader.js";
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  scriptOnloadHandler() {
    window.mailru.loader.require('api', () => {
      window.mailru.app.init(this.private_key);
      console.log('my ok')
      this.init = true;
    });
  }
       
  showInviteBox(message) {
    window.mailru.app.friends.invite({
      text: message,
      logo: '',
    });
  }

  showOrderBox(price, label, value, bonus) {
    let title = "";
    if (bonus > 0){
      title = `${value + bonus} Кристаллов`;
    }
    else{
      title = `${value} Кристаллов`;
    }
    
    window.mailru.app.payments.showDialog({
      service_id: value,
      service_name: title,
      mailiki_price: price,
    });
  }

  showPaymentWindow(price, itemId) {
    let title = "Сундук";

    window.mailru.app.payments.showDialog({
      service_id: 1,
      service_name: title,
      mailiki_price: price,
    }); 
  }

  showSubscriptionBox(action, item) {
  }

  wallPost(message, photo, url, gamer_id) {
    window.mailru.common.stream.post({
      text: message,
      img_url: photo,
      action_links: [{'text': 'посмотреть', 'href': url}],
    });
  }
    
  showRequestBox(friend_id, message, photo) {
    window.mailru.app.friends.request({
      text: message,
      image_url: photo,
      friends: [friend_id]
    });
  }
    
  resizeWindow(height) {
    window.mailru.app.utils.setHeight(height);
  }

  async getFriends() {
   return await this.getFriendsInternal();
  }
  
  async getFriendsInternal(){
    try{
      const response = await new Promise(resolve => {
        window.mailru.common.friends.getExtended(function(list) {
          console.log(list)
          resolve(list);
        });
      });

      console.log(response)

      if (response){
          return response.data.map(f => {
            return {
              id: f.uid,
              name: f.first_name + ' ' + f.last_name,
              photo: f.pic,
            }
          });
      }
    }
    catch(e){
      console.log(e);
      return [];
    }
    
    return [];
  }
  
  async checkQuestCompleted(socialAction){
    return false;
  }

  getShopViewDetails() {
    return {
      diamondPictureSrc: 'assets/shop/gem-big.png',
      diamondIconSrc: 'assets/shop/gem-small.png',
    }
  }

  getCurrencyCases(){
    return {
      nominative: 'мэйлик',
      genitive: 'мэйлика',
      manyGenitive: 'мэйликов'
    };
  }

  waitInit(fun) {
    if(this.init === false) {
      window.setTimeout(() => this.waitInit(fun), 100);
    }
    else{
      return fun()
    }
  }
}
