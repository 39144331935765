import Api from '../api';

const apiInstance = Api.getInstance();

export const getFriends = (socialUin, socConfId, userId) => {
  return apiInstance.get(`/social/friends/?socialUin=${socialUin}&soc_conf_id=${socConfId}&user_id=${userId}`);
};

// plv only
export const getInviteBox = (socConfId, userId) => {
  return apiInstance.get(`/social/invite/?soc_conf_id=${socConfId}&soc_user_id=${userId}`);
};

export const getOrderBox = (socConfId, userId) => {
  return apiInstance.get(`/social/payment_window/?soc_conf_id=${socConfId}&soc_user_id=${userId}`);
};
