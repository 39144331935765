import React, { Component } from 'react';
import { observer } from 'mobx-react';
import './Game.css';

class Game extends Component {
  render() {
    const { gameUrl } = this.props;

    if (!gameUrl.get()) {
      return null;
    }

    return (
      <div className="Game">
        <iframe id="wrapped-game"
          className="Game-iframe"
          title="game"
          src={gameUrl.get()}
          width="100%"
          frameBorder={0}
          border={0}
          hspace={0}
          vspace={0}
        />
      </div>
    );
  }
}

export default observer(Game);
