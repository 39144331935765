import React, { Fragment } from 'react';
import ShopItemButton from './ShopItemButton';
import './ShopItem.css';
import SocialClient from "../../lib/SocialClient";

const ShopItem = ({
  item,
  errorMessage,
  onClick,
  onChange,
}) => {
    const diamondPicture = `${process.env.PUBLIC_URL}/assets/shop/gem-big.png`;
    const diamondIcon = `${process.env.PUBLIC_URL}/assets/shop/gem-small.png`;
    const socialClient = SocialClient.instance;
    
    return (
        <div className="shop-item">
            {item && item.label && (
                <div className="shop-item__ribbon">
                    {item.label}
                </div>
            )}

            <div className="shop-item__pack">
                <img
                    alt="currency"
                    src={diamondPicture}
                />

                {onChange
                    ? (
                        <input
                            className="shop-item__input"
                            type="text"
                            onChange={onChange}
                        />
                    ) : (
                        <span className="shop-item__amount">
                            {item.value}
                        </span>
                    )
                }
            </div>

            <div className="shop-item__gift">
                {item && item.bonus > 0 &&
                    <div className="shop-gift">
          <span className="shop-gift__amount">
            +{item.bonus}
          </span>

                        <img
                            alt="gift"
                            className="shop-gift__symbol"
                            src={diamondIcon}
                        />

                        <span className="shop-gift__label">
            в подарок
          </span>
                    </div>
                }
            </div>

            <div className="shop-item__price">
                {errorMessage && (
                    <div className="shop-item__error">
                        {errorMessage}
                    </div>
                )}

                {item && item.priceWithDiscount > 0
                    ? (
                        <div>
                            {errorMessage || (
                                <Fragment>
                                    <div className="shop-item__price--old">
                                        <s>{`${item.price} ${socialClient.getCurrencyStr(item.price)}`}</s>
                                    </div>
                                    <div className="shop-item__price--current">
                                        {`${item.priceWithDiscount} ${socialClient.getCurrencyStr(item.priceWithDiscount)}`}
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    ) : (
                        <div className="shop-item__price--current">
                            {!errorMessage && item && `за ${item.price} ${socialClient.getCurrencyStr(item.price)}`}
                        </div>
                    )
                }
            </div>
            
            <ShopItemButton onClick={onClick} disabled={errorMessage || item == null}/>
        </div>
    );
}

export default ShopItem;
