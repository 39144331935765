import { getInviteBox, getOrderBox } from '../../actions/social';

export default class PlvAdapter {
  constructor(soc_user_id, soc_conf_id) {
    this.soc_user_id = soc_user_id;
    this.soc_conf_id = soc_conf_id;
  }

  showInviteBox(message) {
    return getInviteBox(this.soc_conf_id, this.soc_user_id);
  }
  
  showOrderBox(value, label) {
    return getOrderBox(this.soc_conf_id, this.soc_user_id);
  }

  showSubscriptionBox(action, item) {
    return false;
  }

  wallPost(message, photo, url, gamer_id) {
    return false;
  }
    
  showRequestBox(friend_id, message, photo) {
    return false;
  }
    
  resizeWindow(height) {
    return false;
  }
}
