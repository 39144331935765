import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import withModalDialog from '../hocs/withModalDialog';
import ShopItem from './ShopItem';
import './Shop.css';

class Shop extends Component {
  state = {
    customItemError: null,
  };

  handleShopInputChange = e => {
    const minPack = this.props.shopItems.get().minShopItemValue;
    const value = e.target.value;

    const matches = value.match(/\D/gi);

    if (matches && matches.length > 0) {
      return this.setState({
        customItemError: 'Можно вводить только цифры!'
      });
    }

    if (minPack && +minPack > +value) {
      return this.setState({
        customItemError: `Минимальное количество покупки: ${minPack}`
      });
    } 
    
    this.props.loadCustomShopItems(value).then(() => {
      this.setState({ customItemError: null });
    });
  };

  handleShopItemClick = item => () => {
    this.props.showOrderBox(item.price, item.label, item.value, item.bonus);
  };

  render () {
    const { shopItems, customShopItem } = this.props;
    const { customItemError } = this.state;
    
    if (!shopItems.get()) {
      return null;
    }

    const items = shopItems.get().shopItems;
    const customItem = customShopItem.get();
    return (
      <Fragment>
        {items.map(item => (
          <ShopItem
            key={item.value}
            item={item}
            onClick={this.handleShopItemClick(item)}
          />
        ))}

        <ShopItem
          item={customItem}
          errorMessage={customItemError}
          onChange={this.handleShopInputChange}
          onClick={this.handleShopItemClick(customItem)}
        />
      </Fragment>
    );
  }
}

export default withModalDialog(Shop, 'shop');
