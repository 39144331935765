import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from '../../ui';

const withModalDialog = (WrappedComponent, className) => {
  return observer(props => {

    if (props.status.get() === 'close') {
      return null;
    }

    return (
      <Modal status={props.status} className={className}>
        <WrappedComponent {...props} />
      </Modal>
    );
  });
};

export default withModalDialog;
