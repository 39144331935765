import Api from '../api';

const apiInstance = Api.getInstance();

export const getQuestMissions = (gamerId, platform) => {
  return apiInstance.get(`/quests/${platform}?gamerId=${gamerId}`);
};

export const takeReward = (gamerId, platform, questMissionId) => {
  return apiInstance.post(`/quests/${platform}/complete?gamerId=${gamerId}&questId=${questMissionId}`, false, '');
};
