import React, { Component } from 'react';
import FriendList from '../friend_list';
import './Footer.css';

class Footer extends Component {
  render () {
    const { friends, showInviteBox, showRequestBox, showQuestMissions } = this.props;

    return (
      <footer className="Footer">
        <FriendList
          friends={friends}
          showRequestBox={showRequestBox}
          showInviteBox={showInviteBox}
          showQuestMissions={showQuestMissions}
        />
      </footer>
    );
  }
}

export default Footer;
