import Api from '../api';

const apiInstance = Api.getInstance();

export const getKnowledgeSections = () => {
  return apiInstance.getStatic(`/content/knowbase.json`);
};

export const getKnowledgeArticle = (articleId) => {
  return apiInstance.getStatic(`/content/articles/${articleId}.json`);
};
