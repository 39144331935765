import Api from '../api';

const apiInstance = Api.getInstance();

export const getShopItems = (platform) => {
  return apiInstance.get(`/shop/${platform}`);
};

export const getCustomShopItem = (platform, value) => {
  return apiInstance.get(`/shop/${platform}/custom?value=${value}`);
};

export const createNewOrder = (gameId, socialUin, socialConfId, cost, value, bonus, gamerId, itemNum) => {
  let params = {
    game_id: gameId,
    social_uin: socialUin,
    social_conf_id: socialConfId,
    cost,
    value,
    bonus,
    gamer_id: gamerId,
  };

  if (itemNum) {
    params.subscription = true;
    params.item = itemNum;
  }
  return apiInstance.post('/new_order', {
    order_transaction: params
  }, false, '');
}
