import Api from '../api';

const apiInstance = Api.getInstance();

export const getIframeUrl = (platform, token) => {
  let url = `/game/origin`;
  
  let params = {
    platform: platform,
    token: token
  }

  return apiInstance.post(url, params);
};

export const getWindowHeight = (gamerId, platform) => {
  let url = `/properties/height?gamerId=${gamerId}&platform=${platform}`;
  
  return apiInstance.get(url);
}
