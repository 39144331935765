import React from 'react';
import MenuItem from './MenuItem';
import './Menu.css';

const Menu = ({
  items,
  onClick,
  currentItemId,
}) => (
  <div className="knowledge__menu">
    {items.map(item => (
      <MenuItem
        key={item.id}
        title={item.title}
        active={item.id === currentItemId}
        onClick={onClick(item)}
      />
    ))}
  </div>
);

export default Menu;
