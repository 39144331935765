import * as signalR from "@microsoft/signalr";
import {HttpTransportType, HubConnection} from "@microsoft/signalr";
import SocialClient from "./SocialClient";
import Store from "../store";

//const URL = `https://localhost:5000/socket`;
const URL = "https://wrapper.playvision.games/socket";

class Connector {
    static instance = null;
    connection = null;
    events = null;
    
    constructor({ store,
                socialUin,
                viewerId,
                gamerId,
                config}) {
        
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${URL}?gamerId=${gamerId}`, {
                withCredentials: false,
                transport: HttpTransportType.WebSockets,
                skipNegotiation: true,
            })
            .withAutomaticReconnect()
            .build();
        
        this.connection.on("setWindowHeight", this.resizeWindow);
        this.connection.on("openPaymentWindow", this.showPaymentWindow);
        this.connection.on("openShop", () => this.openShop(store));
        
        this.connection.start({ jsonp: true }).catch(err => document.write(err));
    }
    newMessage = (messages) => {
        this.connection.send("newMessage", "foo", messages).then(x => console.log("sent"))
    }
    
    resizeWindow(height) {
        SocialClient.instance.resizeWindow(height);
    }

    openShop(store){
        store.shopStatus.set("open");
    }
    
    showPaymentWindow(cost, itemId){
        SocialClient.instance.showPaymentWindow(cost, itemId);
    }
    
    static getInstance(params) {
        if (!Connector.instance)
            Connector.instance = new Connector(params);
        
        return Connector.instance;
    }
}

export default Connector.getInstance;