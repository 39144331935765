export default class Api {
  //static SERVER_URL = `https://localhost:5000`;
  static SERVER_URL = `https://wrapper.playvision.games`;

  static instance = null;

  constructor() {
    if (Api.instance) {
      throw new Error("ups");
    }
  }

  getStatic(url){
    return fetch(`${Api.SERVER_URL}${url}`, this.requestOptions("get"))
        .then(response => {
          return response.json();
        })
        .then(json => {
          console.log(json);
          return Promise.resolve(json);
        });
  }
  
  get(url, auth = true) {
    return fetch(`${Api.SERVER_URL}/api${url}`, this.requestOptions("get", null, auth))
      .then(response => {
        return response.json();
      })
      .then(json => {
        return Promise.resolve(json);
      });
  }
  
  post(url, params, auth = true) {

    return fetch(`${Api.SERVER_URL}/api${url}`, this.requestOptions("post", params, auth))
      .then(response => {
        return response.json();
      })
      .then(json => {
        return Promise.resolve(json);
      }).catch(e => {
        console.error(e);
      });
  }
  
  put(url, params, auth = true) {
    return fetch(`${Api.SERVER_URL}/api${url}`, this.requestOptions("put", params, auth))
      .then(response => {
        return response.json();
      })
      .then(json => {
        return Promise.resolve(json);
      });
  }

  delete(url) {
    return fetch(`${Api.SERVER_URL}/api${url}`, this.requestOptions("delete", {}, true))
      .then(response => {
        return Promise.resolve(response);
      });
  }

  requestOptions(method, params, auth) {
    const headers = {};
    
    headers['Content-Type'] = 'application/json';
    headers['X-Requested-With'] = 'XMLHttpRequest';
    headers['Access-Control-Allow-Origin'] = '*';

    if (auth) headers['Authorization'] = localStorage.getItem('token');

    const options = {
      method,
      headers,
      mode: 'cors'
    };

    if (params) options.body = JSON.stringify(params);

    return options;
  }

  static getInstance() {
    if (!Api.instance) {
      Api.instance = new Api();
    }

    return Api.instance;
  }
}
