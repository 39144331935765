import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Menu } from '../../ui';
import './Header.css';

import navbarBackground from '../../assets/lady/navbar.png';

class Header extends Component {
  get menuItems () {
    const config = this.props.config.get();
    let requiredItems = [
      {
        id: 4,
        title: 'На весь экран',
        image: 'fullscreen',
        onClick: this.props.enterFullscreen,
        position: 4,
      },
      {
        id: 5,
        title: 'База знаний',
        image: 'faq',
        onClick: this.handleKnowledgeClick,
        position: 5,
      },
      {
        id: 6,
        title: 'Магазин',
        image: 'shop',
        onClick: this.handleShopClick,
        position: 6,
      },
    ];

    let optionalItems = [];
    
    if (config) {
      const { community, support, use_subscription, like_widget } = config;
      
      if (use_subscription) {
        optionalItems.push({
          id: 1,
          title: 'Подписки',
          label: 'Подписки',
          image: 'gem',
          onClick: this.handleSubscriptionClick,
          position: 1,
        });
      }

      if (community) {
        optionalItems.push({
          id: 2,
          title: 'Игровое сообщество',
          onClick: () => window.open(community, '_blank').focus(),
          image: 'group',
          position: 2,
        });
      }

      if (support) {
        optionalItems.push({
          id: 3,
          title: 'Поддержка',
          onClick: () => window.open(support, '_blank').focus(),
          image: 'support',
          position: 3,
        });
      }
      
      if (like_widget) {
        optionalItems.push({
          id: 'likes',
          position: 7,
        });
      }
    }

    return [
      ...requiredItems,
      ...optionalItems,
    ].sort((a, b) => a.position - b.position);
  }

  handleShopClick = () => {
    this.props.shopStatus.set("open");
  };
  
  handleKnowledgeClick = () => {
    this.props.knowledgeStatus.set("open");
  };

  handleSubscriptionClick = () => {
    this.props.subscriptionStatus.set("open");
  };

  render() {
    const style = {
      backgroundImage: `url(${navbarBackground})`,
    };

    return (
      <header className="header" style={style}>
        <Menu items={this.menuItems} />
      </header>
    );
  }
}

export default observer(Header);
