import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import withModalDialog from '../hocs/withModalDialog';
import './QuestMissions.css';
import SocialClient from "../../lib/SocialClient";

class QuestMissions extends Component {
  constructor(props) {
    super(props);
    this.setState({ info: undefined })
    
    this.quests = this.props.missions.get();
    this.missions = this.props.missions;
    this.loadCompletedQuestsInfo(this.quests);
    
    this.handleRewardClick = this.props.getQuestMissionReward;
  }

  async loadCompletedQuestsInfo(quests){
    const promises = await quests.map(async (quest) => {
      const completed = await SocialClient.instance.checkQuestCompleted(quest.social_action);
      return {quest: quest, completed: completed};
    });
    
    let info = []
    for (let p of promises){
      info.push(await p);
    }
    
    this.setState({
      info: info
    })
  }
  
  render () {
    if (this.state){
      const quests = this.missions.get();
      if (quests !== this.quests){
        this.quests = quests;
        this.loadCompletedQuestsInfo(this.quests);
      }
      
      return (
          <Fragment>
            <div className="quest-missions__header">
              <h1>Получи награду!</h1>
              <h2>За каждое задание</h2>
              <h3>Информация обновляется через 15 минут</h3>
            </div>
            
            {this.state.info.map(i => {
              const quest = i.quest;
              const completed = i.completed;
              return (
                  <div
                      className="quest-item"
                      key={`quest-missions-${quest.id}`}
                  >
                    <div className={`quest-item__title ${quest.state > 0 ? 'quest-item__title--done' : ''}`}>
                      {quest.title}
                    </div>
                    <div className="quest-item__reward">
                      Награда: {quest.reward}
                    </div>
                    {completed && quest.state === 0 && (
                        <div className="quest-item__button" onClick={() => this.handleRewardClick(quest.id)}>
                          Забрать
                        </div>
                    )}
                    {!completed && quest.state === 0 && (
                        <div className="quest-item__about">
                          {quest.about}
                        </div>
                    )}
                  </div>
              )
            })}
          </Fragment>
      ); 
    }
    else{
      return '';
    }
  }
}

export default withModalDialog(observer(QuestMissions), 'quest-missions');
